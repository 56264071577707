/**
 * External Dependencies
 */
import React, { Component } from 'react';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';

/**
 * Component
 */
 var height =  screen.height;
 console.log(" screen.height", height);


 var my_height = height - 69
class MessengerPage extends Component {
    render() {
        return (
            <PageWrap style={{height:my_height, overflow:"hidden"}}>

                    <AsyncComponent component={ () => import( './content' ) } />

            </PageWrap>
        );
    }
}

export default MessengerPage;
