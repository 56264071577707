/**
 * External Dependencies
 */
import { combineReducers } from 'redux';
import Cookies from 'js-cookie';
import Omit from 'object.omit';

/**
 * Internal Dependencies
 */
import { getUID } from '../utils';
import defaultSettings from '../settings';
import {
    UPDATE_AUTH,
    UPDATE_SETTINGS,
    ADD_TOAST,
    REMOVE_TOAST,
    SORT_AUTH,
} from '../actions';

// initial state.
const INITIAL_SETTINGS_STATE = {
    ...defaultSettings,
};
const INITIAL_AUTH_STATE = {
    crm_token: Cookies.get( 'crm_cookie' ),
};
const INITIAL_TOASTS_STATE = [];

const INITIAL_SORT_STATE = {
  user_name:" ",
  search:"",
  tag:"",
  archive_user_name:"",
  archive_search:"",
  archive_tag:"",

};

/**
 * Reducer
 */
const rootReducer = combineReducers( {
    auth: ( state = INITIAL_AUTH_STATE, action ) => {
        switch ( action.type ) {
        case UPDATE_AUTH:
            // save crm_token to cookies for 3 days.
              if(Cookies.get('stay_login_cookie') == "true"){
                if ( typeof action.auth.crm_token !== 'undefined' ) {
                    Cookies.set( 'crm_cookie', action.auth.crm_token, { expires: 365 });
                }
              }
              else{
                if ( typeof action.auth.crm_token !== 'undefined' ) {
                    Cookies.set( 'crm_cookie', action.auth.crm_token, { expires: 1 });
                }
              }


            return Object.assign( {}, state, action.auth );
        default:
            return state;
        }
    },
    sort: ( state = INITIAL_SORT_STATE, action ) => {
        switch ( action.type ) {
        case SORT_AUTH:
             sort_new :action.sort.user_name;
            return Object.assign( {}, state, action.sort );
        default:
            return state;
        }
    },
    settings: ( state = INITIAL_SETTINGS_STATE, action ) => {
        switch ( action.type ) {
        case UPDATE_SETTINGS:
            return Object.assign( {}, state, action.settings );
        default:
            return state;
        }
    },
    toasts: ( state = INITIAL_TOASTS_STATE, action ) => {
        switch ( action.type ) {
        case ADD_TOAST:
            const newData = {
                ...{
                    title: '',
                    content: '',
                    color: 'brand',
                    time: false,
                    duration: 0,
                    closeButton: true,
                },
                ...action.data,
            };

            if ( newData.time === true ) {
                newData.time = new Date();
            }

            return {
                ...state,
                [ getUID() ]: newData,
            };
        case REMOVE_TOAST:
            if ( ! action.id || ! state[ action.id ] ) {
                return state;
            }
            return Omit( state, action.id );
        default:
            return state;
        }
    },
} );

export default rootReducer;
